/****
 *
 * window & process.env constants
 *
 */
export const ELOQUENT = window.AuthEloquent;

export const AXIOS = window._axios;
export const TRADING_AXIOS = window._tradingAxios;
window._withAsyncCallerAxios = window._TradingBaseAxios;

export const APP_ENV = process.env.REACT_APP_ENV;
export const AUTH_API_URL = process.env.REACT_APP_API_URL;
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const API_ADDRESS = 'https://aged-oasis-di8qclo59nbp.vapor-farm-f1.com/api';

export const STAMPED_PUBLIC_API_KEY = process.env.REACT_APP_STAMPED_PUBLIC_API_KEY;
export const STAMPED_STORE_HASH = process.env.REACT_APP_STAMPED_STORE_HASH;

export const MARCH_PROMO_CODE = {
    key: '1M_promo_code',
    value: '1monthfree'
};

export const APPLY_MARCH_PROMO_CODE = {
    key: 'apply_1M_promo',
    value: true
};

/****
 *
 * Storage keys
 *
 */
export const STORAGEKEY_AUTH_TOKEN = 'sk-authToken';
export const STORAGEKEY_APP_LANGUAGE = 'sk-appLanguage';
export const STORAGEKEY_REFERRAL = 'sk-referral';
export const STORAGEKEY_TRADE_IN_DEVICE = 'trade-in-device-key';
export const STORAGEKEY_PAY_NOW_INFO = 'paynow-number';
export const STORAGEKEY_PASSWORD_SECRET_KEY = 'ae2269f3e1b876ea0af0f4493326bc82';
/****
 *
 * Configs
 *
 */
// App default language (also used for the <html /> lang='' attribute)
// see https://developer.paciellogroup.com/blog/2016/06/using-the-html-lang-attribute/
// NOTE: make sure the language key defined here matches the default helmet key
// // see `executors/i18n/helmet`
export const APP_LANGUAGE_DEFAULT = 'en'; // TODO_REPLACE(en | de | fr | etc..)

// App environments
// see .env.production & .env.development
// values must match the values defined in these files
export const APP_ENVS = {
    LOCAL: 'local',
    STAGING: 'staging',
    PRODUCTION: 'production'
};

// SENTRY - see https://docs.sentry.io/error-reporting/configuration/?platform=javascript
export const SENTRY_CONFIG = {
    environment: APP_ENV
};

// WEBFONT config - see https://github.com/typekit/webfontloader
export const WEBFONT_CONFIG = {
    // google: {
    //     families: [
    //         'Poppins:300',
    //         'Poppins:400',
    //         'Poppins:500',
    //         'Poppins:700',
    //         'Poppins:900'
    //     ]
    // }
};

// INACTIVE Subscriptions Status
export const INACTIVE_STATUS = ['payment_failed', 'completed', 'rejected', 'shortage', 'bought'];

// Social networks config
export const SOCIAL_NETWORK_URLS = {
    FACEBOOK: 'TODO_REPLACE',
    INSTAGRAM: 'TODO_REPLACE',
    TWITTER: 'TODO_REPLACE',
    LINKEDIN: 'TODO_REPLACE'
    // ...
};

export const FACEBOOK_APP_ID = 'TODO_REPLACE';

/****
 *
 * App routing
 *
 */
export const ROUTES = {
    HOME: '/',
    INVITE: '/invite',
    PRODUCTS: '/products',
    ASSORTMENT_PAGE: '/products/:slug',
    PRODUCT: {
        path: '/product/:slug',
        generate: (slug, rentalTerm = null) => {
            if (rentalTerm) {
                return `/product/${slug}/?rental_term=${rentalTerm}`;
            } else {
                return `/product/${slug}`;
            }
        }
    },
    DEALS: '/deals',
    DEAL: {
        path: '/deals/:dealId/:slug',
        generate: (dealId, slug, rentalTerm = null) => {
            if (rentalTerm) {
                return `/deals/${dealId}/${slug}/?rental_term=${rentalTerm}`;
            } else {
                return `/deals/${dealId}/${slug}`;
            }
        }
    },
    HOW_IT_WORKS: '/how-it-works',
    FOR_BUSINESS: '/business',
    FAQ: '/faq',
    DAAS: '/daas',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
    ABOUT_US: '/about-us',
    MY_ACCOUNT: '/my-account',
    PROFILE: '/profile',
    VISION_PRO: '/apple-vision-pro',
    BILLING_HISTORY: '/profile/billing-history',
    SINGPASS: '/singpass',
    CART: '/cart',
    SHIPPING: '/shipping',
    PAYMENT: '/payment',
    ORDER_SUMMARY: '/order-summary',
    ORDEREDPRODUCT: (id = ':id', orderID = ':orderID') => `/ordered-product/${id}/${orderID}`,
    SINGPASS_MANUALL_FORM: '/manuall',
    SINGPASS_RESULT: '/singpass-result',
    MYINFORMATION: '/my-account/my-information',
    SUBSCRIPTIONS: '/my-account/subscriptions',
    INACTIVE_SUBSCRIPTIONS: '/my-account/inactive-subscriptions',
    ORDERS: '/my-account/orders',
    PAYMENTINFO: '/my-account/payment-info',
    SHIPPINGINFO: '/my-account/shipping-info',
    ORDER: (id = ':id') => `/my-account/orders/${id}`,
    CONTACTUS: '/contact-us',
    ADDREVIEW: '/add-review',
    REFER_AND_EARN: '/my-account/refer-and-earn',
    LUCKY_DRAW: '/lucky-draw',
    APPLE_VISION_PRO: '/apple-vision-pro',
    IMEI_LOOKUP: '/imei-lookup',
    ORDER_CONFIRMATION: '/order-confirmed',
    FLASH_DEAL: {
        path: '/flash-deals/:dealId/:slug',
        generate: (dealId, slug, rentalTerm = null) => {
            if (rentalTerm) {
                return `/flash-deals/${dealId}/${slug}/?rental_term=${rentalTerm}`;
            } else {
                return `/flash-deals/${dealId}/${slug}`;
            }
        }
    },
    SEARCH: {
        path: '/search',
        generate: (query = null) => {
            if (query !== '') {
                return `/search?query=${query}`;
            }
        }
    },
    INVOICE: (invoiceUrl = ':invoiceUrl') => `/my-account/invoice/${invoiceUrl}`
};

export const ROUTE_REDIRECT_AFTER_REGISTRATION = ROUTES.HOME;
export const ROUTE_REDIRECT_AFTER_LOGIN = ROUTES.HOME;
export const ROUTE_REDIRECT_FROM_UNAUTHED_ROUTE = ROUTES.HOME;
export const ROUTE_REDIRECT_FROM_AUTHED_ROUTE = ROUTES.HOME;

/*
 *
 * Project config
 *
 */
export const APP_CURRENCY_SYMBOL = '$';
export const APP_CURRENCY_NAME = 'SGD';

// https://docs.google.com/presentation/d/1s_D-QRj8jAp_PKBZsHll6tUv_6J6en5JUKcdSPlq6do/edit#slide=id.g1362d62e662_0_11
// for issues : https://docs.google.com/spreadsheets/d/12KsXFP834zwxPfMRmJghVmNByt2AdlJrkXG-SydYCOY/edit?usp=sharing
// ravio doc https://docs.google.com/spreadsheets/d/14eLgOOX6Tx6YYmdjC3rN843X13L2vmXRMg8llwe7I-g/edit#gid=599515743
// coralis doc https://docs.google.com/spreadsheets/d/1kIwpdTTUnu8RxvswQ-O1Z9BQcvnbH02VDT9-LWfTeaQ/edit#gid=599515743
// roadmap : https://docs.google.com/spreadsheets/d/1uyEVrnzwOii74YirOEKlfiNPi9Kw1N3Vkoc5yq_zTg8/edit#gid=1279238413

// cinch new staging url
// http://d81ftngso57y6.cloudfront.net/

export const TRADE_IN_DEVICES = [
    { key: 'samsung_s21_s23', series: 'Galaxy S21-S23', cashback: `${APP_CURRENCY_NAME} 200.00` },
    { key: 'iphone_12_14', series: 'iPhone 12-14', cashback: `${APP_CURRENCY_NAME} 300.00` },
    { key: 'none', series: 'No Thanks', cashback: '' }
];

export const SAMSUNG_WEB_LINK = 'https://samsung.cinch.sg/';

export const CHATBOT_ROUTES = [ROUTES.SINGPASS, ROUTES.PRODUCT.path, ROUTES.HOME, ROUTES.MYINFORMATION, ROUTES.CART];

export const IPHONE_16_SLUGS = [
    'apple-iphone-16',
    'apple-iphone-16-plus',
    'apple-iphone-16-pro',
    'apple-iphone-16-pro-max'
];
